const apiKeys = {
  firebaseConfig: {
    apiKey: 'AIzaSyBlPX_PN0lpRbO-rSt7-HPeRmf7WQo1FTE',
    authDomain: 'personal-bio-site-typescript.firebaseapp.com',
    projectId: 'personal-bio-site-typescript',
    storageBucket: 'personal-bio-site-typescript.appspot.com',
    messagingSenderId: '1079391243437',
    appId: '1:1079391243437:web:9bd83e72941d1fa1154a24',
    measurementId: 'G-0JGH1RZ7NS',
  },
};

export default apiKeys;
